import React from 'react';
import { toast, cssTransition } from "react-toastify";
import PropTypes from "prop-types";
// import { IoCheckmarkCircle } from '@react-icons/all-files/io5/IoCheckmarkCircle'
// import { IoInformationCircle } from '@react-icons/all-files/io5/IoInformationCircle'
// import { IoCloseCircle } from '@react-icons/all-files/io5/IoCloseCircle'
// import { RiErrorWarningFill } from '@react-icons/all-files/ri/RiErrorWarningFill'

const slideinBlur = cssTransition({
  enter: "slide-in-blurred-right",
  exit: "slide-out-blurred-right"
});

const Msg = ({ toastProps, msg }) => {
  return (
    <>
      {/* {toastProps.type === 'success' &&
                <div className='toast__icon toast__icon--success'>
                  <IoCheckmarkCircle fontSize="24px" />
                </div>
      }
      {toastProps.type === 'info' &&
                <div className='toast__icon toast__icon--info'>
                  <IoInformationCircle fontSize="24px" />
                </div>
      }
      {toastProps.type === 'warning' &&
                <div className='toast__icon toast__icon--warning'>
                  <RiErrorWarningFill fontSize="24px" />
                </div>
      }
      {toastProps.type === 'error' &&
                <div className='toast__icon toast__icon--error'>
                  <IoCloseCircle fontSize="24px" />
                </div>
      } */}
      <div className='custom__toast' dangerouslySetInnerHTML={{ __html: msg }}></div>
    </>
  )
}

const success = (msg) => {
  toast.success(<Msg msg={msg} />, {transition: slideinBlur})
}

const error = (msg) => {
  toast.error(<Msg msg={msg} />, {transition: slideinBlur})
}

const warn = (msg) => {
  toast.warn(<Msg msg={msg} />, {transition: slideinBlur})
}

const info = (msg) => {
  toast.info(<Msg msg={msg} />, {transition: slideinBlur})
}

Msg.propTypes = {
  toastProps: PropTypes.object,
  msg: PropTypes.string
}

export { success, error, info, warn };

