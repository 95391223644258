import { post } from '../services/Fetch';
import Cookie from "./Cookie";

const SlackWebHook = {

  /**
     * send error log to slack
     * @author: Arijit Deb Roy
     * @params: page name, func name and error message
     */

  sendSlackMessage(page, func, error, username = process.env.NEXT_PUBLIC_BRAND_NAME+"-Testprep", channel = process.env.NEXT_PUBLIC_SLACK_CHANNEL, icon_emoji = ":scream:", slackUrl = process.env.NEXT_PUBLIC_SLACK_URL){
    if(error.includes('403')) {
      Cookie.deleteCookie("_token");
      window.location.href = "/";
    }
    let text = `${page}${page !== "" ? '-' : ''}${func}${func !== "" ? '-' : ''}${error}`;
    let message =  {text, channel, username, icon_emoji};

    return post(`${slackUrl}`, message, null)
      .then(res => res.data)
      .catch(error => error);

  }

}

export default SlackWebHook;