import React, { Component } from "react";
import Link from "next/link";
import PropTypes from 'prop-types';
import { MdMenu } from "@react-icons/all-files/md/MdMenu";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { MdArrowForward } from "@react-icons/all-files/md/MdArrowForward";
import { MdSchool } from "@react-icons/all-files/md/MdSchool";
import ipdata from "../../services/Location";
import { withRouter } from "next/router";
import Navigation from "../../config/navigation.json"

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipdata: {},
      currentRoute: "",
      loading: true,
    };
  }

  componentDidMount() {
    ipdata
      .getLocation()
      .then((res) => {
        if (res) {
          this.setState({
            ipdata: JSON.parse(
              localStorage.getItem("user_location_details_local")
            ).geoLocationDetails,
            loading: false
          });
        }
      })
      .catch((err) => {
        console.log(err.message)
        this.setState({loading: false})
      });
    this.mobileNavFunctions();
  }

  mobileNavFunctions = () => {
    const showNav = () => {
      document.querySelector(".landing-mobile-nav").classList.add("show");
      document
        .querySelector(".landing-mobile-header--overlay")
        .classList.add("show");
    };
    const hideNav = () => {
      document.querySelector(".landing-mobile-nav").classList.remove("show");
      document
        .querySelector(".landing-mobile-header--overlay")
        .classList.remove("show");
    };
    document.querySelector(
      ".landing-mobile-header--icon"
    ).onclick = function() {
      showNav();
    };
    document.querySelector(".landing-mobile-nav--close").onclick = function() {
      hideNav();
    };
    document.querySelector(
      ".landing-mobile-header--overlay"
    ).onclick = function() {
      hideNav();
    };
    document.querySelector(
      ".landing-mobile-nav--links li"
    ).onclick = function() {
      hideNav();
    };
  };

  linkNavigation = (route) => {
    this.props.router.push(process.env.NEXT_PUBLIC_STATIC_URI + route);
  };

  render() {
    const { router } = this.props;
    const IndiaNav = () => {
      return (
        <ul className="navigation-links">
          {
            Navigation?.SAT &&
            <li>
              <a href={process.env.NEXT_PUBLIC_STATIC_URI + "course/sat"}>SAT</a>
            </li>
          }
          {
            Navigation?.ACT &&
            <li>
              <a href={process.env.NEXT_PUBLIC_STATIC_URI + "course/act"}>ACT</a>
            </li>
          }
          {
            Navigation?.MOCK_TEST &&
            <li>
              <a href={process.env.NEXT_PUBLIC_STATIC_URI + "mocktest"}>Mock Tests</a>
            </li>
          }
          {
            Navigation?.ADMISSION &&
            <li className="nav-button">
              <a href={process.env.NEXT_PUBLIC_ADMISSION_URI}>
                <span className="d-flex align-items-center">
                  <MdSchool className="me-2"/> Admissions
                </span>
              </a>
            </li>
          }
          {
            !Navigation?.MORE && Navigation?.ONLINE_CLASS &&
            <li>
              <Link href={process.env.NEXT_PUBLIC_STATIC_URI + "sat-online-classes"}><a>SAT Online Classes</a></Link>
            </li>
          }
          {
            !Navigation?.MORE && Navigation?.BLOG &&
            <li>
              <Link href={process.env.NEXT_PUBLIC_STATIC_URI + "blog"}><a>Blog</a></Link>
            </li>
          }
          {
            Navigation?.MORE &&
            <li className="dropdown d-flex">
              <a id="dropdownMenuButton" className="pointer d-flex justify-content-start" data-bs-toggle="dropdown">
                <label className="mb-0 pointer">MORE</label>
                <span className="badge badge-primary my-auto ms-2 rounded-0 fw-bolder" style={{fontSize:10,padding: "5px 8px"}}>NEW</span>
              </a>
              <div className="dropdown-menu shadow-lg border-0 px-3 mt-2" aria-labelledby="dropdownMenuButton">
                {
                  Navigation?.EDUCATOR &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + "educators"}><a className="dropdown-item p-2 pl-2">Educators</a></Link>
                }
                {
                  Navigation?.ONLINE_CLASS &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + "online-classes"}><a className="dropdown-item p-2 pl-2">Online Classes</a></Link>
                }
                {
                  Navigation?.AI_TOOL &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + "essay"}><a className="dropdown-item p-2 pl-2">AI Essay Tool</a></Link>
                }
                {
                  Navigation?.BLOG &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + "blog"}><a className="dropdown-item p-2 pl-2">Blog</a></Link>
                }
              </div>
            </li>
          }
        </ul>
      );
    };
    const InternationalNav = () => {
      return (
        <ul className="navigation-links">
          {
            Navigation?.SAT &&
            <li>
              <a href={process.env.NEXT_PUBLIC_STATIC_URI + "course/sat"}>SAT</a>
            </li>
          }
          {
            Navigation?.ACT &&
            <li>
              <a href={process.env.NEXT_PUBLIC_STATIC_URI + "course/act"}>ACT</a>
            </li>
          }
          {
            Navigation?.MOCK_TEST &&
            <li>
              <a href={process.env.NEXT_PUBLIC_STATIC_URI + "mocktest"}>Mock Tests</a>
            </li>
          }
          {
            Navigation?.EDUCATOR &&
            <li>
              <a href={process.env.NEXT_PUBLIC_STATIC_URI + "educators"}>Educators</a>
            </li>
          }
          {
            !Navigation?.MORE && Navigation?.AI_TOOL &&
            <li>
              <a href={process.env.NEXT_PUBLIC_STATIC_URI + "essay"}>AI Essay Tool</a>
            </li>
          }
          {
            !Navigation?.MORE && Navigation?.BLOG &&
            <li>
              <a href={process.env.NEXT_PUBLIC_STATIC_URI + "blog"}>Blog</a>
            </li>
          }
          {
            Navigation?.MORE &&
            <li className="dropdown d-flex">
              <a id="dropdownMenuButton" className="pointer d-flex justify-content-start" data-bs-toggle="dropdown">
                <label className="mb-0 pointer">MORE</label>
                <span className="badge badge-primary my-auto ms-2 rounded-0 fw-bolder" style={{fontSize:10,padding: "5px 8px"}}>NEW</span>
              </a>
              <div className="dropdown-menu shadow-lg border-0 px-3 mt-2" aria-labelledby="dropdownMenuButton">
                {
                  Navigation?.AI_TOOL &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + "essay"}><a className="dropdown-item p-2 pl-2">AI Essay Tool</a></Link>
                }
                {
                  Navigation?.BLOG &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + "blog"}><a className="dropdown-item p-2 pl-2">Blog</a></Link>
                }
              </div>
            </li>
          }
        </ul>
      );
    };
    const Loading = () => {
      return (
        <ul className='navigation-links'>
          {
            [...Array(5)].map((val,i) => (
              <li key={i}>
                <div className="content-loading rounded" style={{width: 80, height: 30}}></div>
              </li>
            ))
          }
        </ul>
      );
    };
    const RightSideLoader = () => {
      return (
        <ul className='navigation-links'>
          {
            [...Array(2)].map((val,i) => (
              <li key={i}>
                <div className="content-loading rounded" style={{width: 80, height: 30}}></div>
              </li>
            ))
          }
        </ul>
      );
    }
    return (
      <div>
        <div className="navigation-wrapper">
          <div className="container-fluid flex-row justify-content-between align-items-center">
            <div className="flex-row">
              <div className={`logo-wrapper me-2 ${process.env.NEXT_PUBLIC_BRAND_NAME?.toLowerCase()}`}>
                <Link href="/">
                  <picture>
                    <source type="image/webp" srcSet={process.env.NEXT_PUBLIC_BRAND_LOGO || "/images/logo.webp"}/>
                    <source type="image/png" srcSet={process.env.NEXT_PUBLIC_BRAND_LOGO || "/images/logo.png"} />
                    <img src={process.env.NEXT_PUBLIC_BRAND_LOGO || "/images/logo.svg"} alt="Brand-Logo" />
                  </picture>
                </Link>
              </div>

              <div className="nav-topics">
                { this.state.loading ? <Loading/>:
                  this.state.ipdata.country_code === "IN" ? (
                    <IndiaNav />
                  ) : (
                    <InternationalNav />
                  )
                }
              </div>
            </div>
            <div className="nav-right-wrapper">
              {
                this.state.loading ? <RightSideLoader />
                  :
                  <ul>
                    {
                      Navigation?.PRICING &&
                      <li>
                        <a href={process.env.NEXT_PUBLIC_STATIC_URI + "pricing"}>Pricing</a>
                      </li>
                    }
                    {
                      (router.pathname != "/Login" && router.pathname !== "/Checkout" && router.pathname !== "/ForgetPass") &&
                      <li className="nav-button">
                        <a href={"/"}>
                          <button className="btn-solid-small --secondary">
                            Sign In <MdArrowForward className="ms-2" />
                          </button>
                        </a>
                      </li>
                    }
                    {
                      router.pathname != "/Register" &&
                      <>
                          {
                            router.pathname === "/Checkout" ?
                              <li className="nav-button">
                                <a href={window.location.pathname + "?id=" + router.query.id + "&action=signup"}>
                                  <button className="btn-solid-small --secondary">
                                            Sign Up <MdArrowForward className="ms-2" />
                                  </button>
                                </a>
                              </li>
                              :
                              <li className="nav-button">
                                <a href={"/signup"}>
                                  <button className="btn-solid-small --secondary">
                                  Sign Up <MdArrowForward className="ms-2" />
                                  </button>
                                </a>
                              </li>
                          }
                      </>
                    }
                  </ul>
              }
            </div>
          </div>
        </div>
        <div>
          <div className="landing-mobile-header navigation-wrapper">
            <div className="d-flex justify-content-start align-items-center">
              <div className="landing-mobile-header--icon pointer">
                <MdMenu fontSize="24px" />
              </div>
              <div className="logo-wrapper middle my-0">
                <Link href="/">
                  <picture>
                    <source type="image/webp" srcSet={process.env.NEXT_PUBLIC_BRAND_LOGO || "/images/logo.webp"} />
                    <source type="image/png" srcSet={process.env.NEXT_PUBLIC_BRAND_LOGO ||"/images/logo.png"} />
                    <img src={process.env.NEXT_PUBLIC_BRAND_LOGO || "/images/logo.webp"} alt="Brand-Logo" />
                  </picture>
                </Link>
              </div>
            </div>
          </div>

          <div className="landing-mobile-header--overlay" />

          <div className="landing-mobile-nav">
            <div className="landing-mobile-nav--close pointer">
              <MdClose fontSize="24px" />
            </div>
            <div className="landing-mobile-nav--links flex-column justify-content-start align-items-start">
              {this.state.loading ? <Loading />:
                this.state.ipdata.country_code === "IN" ? (
                  <IndiaNav />
                ) : (
                  <InternationalNav />
                )
              }
              <ul className="navigation-links">
                {
                  Navigation?.PRICING &&
                  <li>
                    <a href={process.env.NEXT_PUBLIC_STATIC_URI + "pricing"}>Pricing</a>
                  </li>
                }
              </ul>
              <div className="landing-mobile-nav--links_bottom">
                {
                  router.pathname != "/Login" &&
                      <>
                          {
                            router.pathname === "/Checkout" ?
                              <a href={window.location.pathname + "?id=" + router.query.id + "&action=signin"}>
                                <button className="btn-solid-normal --secondary w-100 mb-4">
                                          Sign In
                                </button>
                              </a>
                              :
                              <a href={"/"}>
                                <button className="btn-solid-normal --secondary w-100 mb-4">
                                          Sign In
                                </button>
                              </a>
                          }
                      </>
                }
                {
                  router.pathname != "/Register" &&
                      <>
                          {
                            router.pathname === "/Checkout" ?
                              <a href={window.location.pathname + "?id=" + router.query.id + "&action=signup"}>
                                <button className="btn-solid-normal --secondary w-100">
                                          Sign Up
                                </button>
                              </a>
                              :
                              <a href={"/signup"}>
                                <button className="btn-solid-normal --secondary w-100">
                                          Sign Up
                                </button>
                              </a>
                          }
                      </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const propTypes = {
  router: PropTypes.object
};

Navbar.propTypes = propTypes;


export default withRouter(Navbar);