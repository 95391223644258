import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';

const fetcher = (...args) => {
  return window.fetch(...args);
};

const Client = new ApolloClient({
  link: new createHttpLink({
    uri: process.env.NEXT_PUBLIC_END_POINT,
    credentials: 'include',
    fetch: fetcher,
  }),
  cache: new InMemoryCache({
    addTypename: false
  })
});

export default Client;
