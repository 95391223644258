/***
 *
 * Get the token from cookie and parse it to get valid json
 * CheckCookie function also check for cookies existense
 * Which holds user Informations.
 *
 * Author; Arijit Deb Roy
 * Date: 2nd sep,2019
 *
 * @param: void
 * @return: <JSON>
 *
 */
import Cookie from './Cookie';

const AuthCheck = {
  checkAuth(){
    if(Cookie.getCookie('_token')){
      var base64Url = Cookie.getCookie('_token').split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);

    }else{
      return false;
    }
  },

  checkCookie(){
    if(Cookie.getCookie('_token'))
      return true;
    else
      return false;
  }

}

export default AuthCheck;